import * as React from "react"
import { Link } from "gatsby"
import Seo from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'

const NotFoundPage = () => {
  return (
    <>
    <Seo title="Not found" description="we couldn't find what you were looking for" image="" pathname="" />
    <Header />
    <div className="bg-orange">
      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex justify-between">
          <div className="w-6/12">
            <img src="/img/default.jpg" className="object-cover w-full h-full" />
          </div>
          <div className="w-6/12 px-12 py-36">
            <div className="leading-tight text-3xl text-white font-bold">
              <h2 className="mb-12"><span className="bg-white text-4xl text-orange px-3 py-1">Page not found</span></h2>
              <p>We couldn't find what you were looking for.</p>
            </div>
            <Link to="/" className="mt-12 inline-block bg-orange px-3 py-1 text-3xl text-white font-bold">Back home</Link>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  )
}

export default NotFoundPage
